var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-modal', {
    attrs: {
      "modalInstance": _vm.modalCreateReturnProtocol
    },
    on: {
      "ok": _vm.createReturnProtocolPdf
    }
  }, [_c('base-cars-return-protocol-form', {
    attrs: {
      "feedback": _vm.feedback
    },
    model: {
      value: _vm.fields,
      callback: function ($$v) {
        _vm.fields = $$v;
      },
      expression: "fields"
    }
  }), _c('a', {
    ref: "exportAnchorRef",
    staticClass: "d-none"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }