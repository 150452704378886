var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-form', {
    attrs: {
      "i18nNamespace": `form.base.protocol.return`,
      "fallbackI18nNamespace": "form.base.protocol.return",
      "enabledFields": Object.keys(_vm.feedback),
      "errors": _vm.feedback
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "date"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę...",
      "reset-button": ""
    },
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "cadreEmployeeId"
    }
  }, [_c('cadre-employee-picker', {
    model: {
      value: _vm.resource.cadreEmployeeId,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "cadreEmployeeId", $$v);
      },
      expression: "resource.cadreEmployeeId"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "documentPreparationPlace"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Gdańsk"
    },
    model: {
      value: _vm.resource.documentPreparationPlace,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "documentPreparationPlace", $$v);
      },
      expression: "resource.documentPreparationPlace"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "carEquipment"
    }
  }, [_c('editor', {
    model: {
      value: _vm.resource.carEquipment,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "carEquipment", $$v);
      },
      expression: "resource.carEquipment"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "includedMileageRecords",
      "labelCols": "12"
    }
  }, [_c('b-form-checkbox', {
    model: {
      value: _vm.resource.includedMileageRecords,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "includedMileageRecords", $$v);
      },
      expression: "resource.includedMileageRecords"
    }
  }, [_vm._v(" Dołączono ewidencję przebiegu ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }