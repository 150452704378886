var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    staticClass: "base-cars-details-card",
    attrs: {
      "with-controls": "",
      "title": _vm.title,
      "dropdownItems": _vm.actionItems
    },
    on: {
      "edit": _vm.openEditModal,
      "delete": _vm.deleteResource
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        var _vm$resource$category;
        return [_c('div', {
          staticClass: "footer-button-wrapper"
        }, [(_vm$resource$category = _vm.resource.category) !== null && _vm$resource$category !== void 0 && _vm$resource$category.be_reported ? _c('vs-button', {
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": _vm.navigateToMileage
          }
        }, [_vm._v(" Kilometrówka ")]) : _vm._e(), _c('b-dropdown', {
          attrs: {
            "id": "dropdown-documents",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_vm._v(" Dokumenty "), _c('feather-icon', {
                attrs: {
                  "icon": "ChevronDownIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }])
        }, _vm._l(_vm.documentItems, function (_ref, idx) {
          var title = _ref.title,
            callback = _ref.callback;
          return _c('b-dropdown-item', {
            key: idx,
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return function () {
                  return callback();
                }.apply(null, arguments);
              }
            }
          }, [_vm._v(" " + _vm._s(title) + " ")]);
        }), 1)], 1)];
      },
      proxy: true
    }])
  }, [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": "Pobieranie danych maszyny..."
    }
  }) : _vm._e(), _c('details-table', {
    attrs: {
      "entries": _vm.entries,
      "i18n-group": "base.cars.details.field"
    },
    scopedSlots: _vm._u([{
      key: "value-status",
      fn: function () {
        return [_c('vs-status-badge', {
          attrs: {
            "data": _vm.resource.status
          }
        })];
      },
      proxy: true
    }, {
      key: "value-company",
      fn: function () {
        var _vm$resource$last_act, _vm$resource$last_act2;
        return [_c('crm-company-label', {
          attrs: {
            "integrated": (_vm$resource$last_act = _vm.resource.last_activity) === null || _vm$resource$last_act === void 0 ? void 0 : (_vm$resource$last_act2 = _vm$resource$last_act.collaboration) === null || _vm$resource$last_act2 === void 0 ? void 0 : _vm$resource$last_act2.company,
            "short": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "value-user",
      fn: function () {
        return [_c('cadre-employee-label', {
          attrs: {
            "name-only": "",
            "data": _vm.resource.employee
          }
        })];
      },
      proxy: true
    }, {
      key: "value-gps",
      fn: function () {
        return [_c('a', {
          staticClass: "gps-panel-link",
          attrs: {
            "href": _vm.resource.gps_panel,
            "target": "_blank"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ExternalLinkIcon"
          }
        }), _vm._v(" Otwórz w nowej karcie ")], 1)];
      },
      proxy: true
    }, {
      key: "value-card",
      fn: function () {
        return [_c('div', {
          staticClass: "value-card"
        }, [_vm._v(" " + _vm._s(_vm.entries.card) + " "), _vm.pinVisible && _vm.resource.fuel_card_pin ? [_c('span', {
          staticClass: "fuel-card-pin-exposed",
          on: {
            "click": _vm.togglePinVisibility
          }
        }, [_vm._v(" " + _vm._s(_vm.resource.fuel_card_pin) + " ")])] : _vm.resource.fuel_card_pin ? [_c('vs-button', {
          staticClass: "pin-button",
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": _vm.togglePinVisibility
          }
        }, [_c('feather-icon', {
          staticClass: "icon",
          attrs: {
            "icon": "KeyIcon"
          }
        }), _vm._v(" PIN ")], 1)] : _vm._e()], 2)];
      },
      proxy: true
    }])
  }), _c('base-resource-qr-modal', {
    attrs: {
      "resource": _vm.resource
    }
  }), _c('base-cars-handover-protocol-modul', {
    attrs: {
      "resource": _vm.resource
    }
  }), _c('base-cars-return-protocol-modul', {
    attrs: {
      "resource": _vm.resource
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }