<template>
  <vs-form
    :i18nNamespace="`form.base.protocol.handover`"
    fallbackI18nNamespace="form.base.protocol.handover"
    :enabledFields="Object.keys(feedback)"
    :errors="feedback"
  >
    <vs-form-group
      field="date"
      >
      <vs-datepicker
        placeholder="Wybierz datę..."
        v-model="date"
        reset-button
      />
    </vs-form-group>

    <vs-form-group field="cadreEmployeeId">
      <cadre-employee-picker
        v-model="resource.cadreEmployeeId"
      />
    </vs-form-group>

    <vs-form-group field="carEquipment">
      <editor
        v-model="resource.carEquipment"
      />
    </vs-form-group>

    <vs-form-group
      field="isForPrivatePurpouses"
      labelCols="12"
    >
      <b-form-checkbox
        v-model="resource.isForPrivatePurpouses"
      >
        Auto będzie używane do celów prywatnych
      </b-form-checkbox>
    </vs-form-group>
  </vs-form>
</template>

<script>
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import { BFormCheckbox } from 'bootstrap-vue';
import { computed } from 'vue';
import CadreEmployeePicker from '@/components/module/cadre/CadreEmployeePicker.vue';
import Editor from '@/components/ui/vs-wysiwyg/Editor.vue';
import formatDate from '@/libs/date-formatter';

export default {
  name: 'BaseCarsHandoverProtocolForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const resource = computed(() => props.value);

    const date = computed({
      get() {
        return formatDate.standard(resource.value.date);
      },
      set(newDate) {
        resource.value.date = newDate;
      },
    });

    return {
      resource,
      date,
    };
  },
  components: {
    VsForm,
    VsFormGroup,
    VsDatepicker,
    BFormCheckbox,
    CadreEmployeePicker,
    Editor,
  },
};
</script>
